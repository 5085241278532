"use client"; // Error components must be Client Components

import { useEffect } from "react";
import Button from "@/components/common/shared/Button/button";

/**
 * @param {{
 *  error:  Error & { digest?: string },
 *  reset: () => void
 * }} param0
 * @returns
 */
export default function AppError({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error("App error: ", error);
  }, [error]);

  return (
    <section className="signup register">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="formBox noBoxShadow">
              <div className="form-inner-box back-login-border  bg-tertiary-white flex flex-col items-center justify-center mx-auto w-12/12">
                <h4 className="lg:text-headline-4 xs:text-headline-5-b text-headline-5 mb-2 text-primary-blue-900 xs:mt-44 mt-24">
                  Something went wrong!
                </h4>
                <p className="text-body-text-2 text-primary-black-800 text-center">
                  Application error: (see the browser console for more
                  information)
                  <Button
                    type="button"
                    theme="blue-btn"
                    className="xs:mt-44 mt-[28px]"
                    onClick={reset}
                  >
                    Try again
                  </Button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
